<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 10.667L4.66667 13.3337L7.33333 10.667" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.66699 13.3337V2.66699" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.33301 2.66699H13.9997" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.33301 5.33301H11.9997" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.33301 8H9.99967" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'CustomSelectIconSort',
}
</script>
